import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import toConvertDateTimezoneToConventionalDate from '@/utils/toConvertDateTimezoneToConventionalDate'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useRewardMoneyList() {
  // Use toast
  const toast = useToast()

  const refRewardsMoneyListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'id', label: 'ID', sortable: true,
    },
    {
      key: 'requestDate', label: 'Data de Solicitação', sortable: true, formatter: value => toConvertDateTimezoneToConventionalDate(value),
    },
    {
      key: 'indicator', label: 'Nome Indicador',
    },
    {
      key: 'rewardValue', label: 'Valor', sortable: true, formatter: value => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
    },
    {
      key: 'expireDate', label: 'Prazo Pagamento', sortable: true, formatter: value => toConvertDateTimezoneToConventionalDate(value),
    },
    {
      key: 'payoutDate', label: 'Status', sortable: true,
    },
    { key: 'actions', label: 'Baixar' },
  ]
  const perPage = ref(25)
  const totalRewards = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('requestDate')
  const isSortDirDesc = ref(false)
  const stageFilter = ref(null)
  // const filterDatesFilter = ref(null)
  // const statusFilter = ref(null)
  // const campaignsFilter = ref(null)
  // const campaignsList = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refRewardsMoneyListTable.value ? refRewardsMoneyListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRewards.value,
    }
  })

  const refetchData = () => {
    refRewardsMoneyListTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  const fetchRewardsVoucher = (ctx, callback) => {
    const payload = {
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      stage: stageFilter.value,
      // status: statusFilter.value,
      // campaign: campaignsFilter.value,
    }
    store
      .dispatch('app-rewards-money/fetchRewardsMoney', payload)
      .then(response => {
        // const { rewards, total } = response.data
        if (response.data) {
          const rewards = response.data.result.payments
          const total = response.data.result.qtyRegister
          callback(rewards)
          totalRewards.value = total
        }
      })
      .catch(error => error)
  }

  const handlePayment = paymentToken => {
    store.dispatch('app-rewards-money/handlePayment',
      paymentToken)
      .then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Sucesso!',
            text: 'Status de recompensa atualizado com sucess!',
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Ocorreu um erro',
            text: 'Tente novamente',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .finally(() => refetchData())
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveIndicateStageIcon = stage => {
    if (stage === 'Em Andamento') return 'ClockIcon'
    if (stage === 'Cancelado') return 'XIcon'
    if (stage === 'Concluído') return 'CheckIcon'
    return 'ClockIcon'
  }
  const resolveIndicateStageVariant = stage => {
    if (stage === 'Em Andamento' || stage === 'Em andamento') return 'light-warning'
    if (stage === 'Cancelado' || stage === 'cancelado') return 'light-danger'
    if (stage === 'Concluído' || stage === 'Concluido') return 'light-success'
    return 'light-dark'
  }

  return {
    fetchRewardsVoucher,
    tableColumns,
    perPage,
    currentPage,
    totalRewards,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refRewardsMoneyListTable,

    resolveIndicateStageIcon,
    resolveIndicateStageVariant,
    refetchData,
    handlePayment,

    // Extra Filters
    // statusFilter,
    stageFilter,
    // campaignsFilter,
    // filterDatesFilter,

    // campaignsList,
  }
}
