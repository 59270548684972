<script>
import { BListGroup, BListGroupItem, BCardText } from 'bootstrap-vue'
import formatMobileNumber from '@/utils/formatMobilePhone'
import toConvertDateTimezoneToConventionalDate from '@/utils/toConvertDateTimezoneToConventionalDate'

export default {
  components: {
    BListGroup, BListGroupItem, BCardText,
  },
  props: {
    item: {
      type: Object,
      default: () => ({
        id: '#9635',
        payoutDate: null,
        requestDate: '15/01/2021',
        expireDate: '13/02/2021',
        rewardValue: 1500,
        indicator: {
          name: 'João da Silva Santos',
          phone: '21986142333',
          cpf: '145.960.627-22',
        },
        bank: {
          type: 'Conta corrente',
          bank: '030 - Banco do Brasil',
          agency: '0001',
          account: '1234-4',
        },
      }),
    },
  },
  setup() {
    return {
      formatNumber: formatMobileNumber,
      convertDate: toConvertDateTimezoneToConventionalDate,
    }
  },
}
</script>
<template>
  <b-list-group>
    <b-list-group-item
      active
      class="flex-column align-items-start"
    >
      <div class="d-flex w-100 justify-content-between">
        <h5 class="mb-1 text-white">
          Contato do parceiro
        </h5>
        <small class="text-secondary">Data de Solicitação: <br> {{ convertDate(item.requestDate) }}</small>
      </div>
      <b-card-text class="mb-1">
        <span class="font-weight-bold d-block text-nowrap">
          {{ item.indicator.name }}
        </span>
        <small class="text-muted d-block">{{ formatNumber(item.indicator.phone) }}</small>
        <small class="text-muted d-block">CPF: {{ item.indicator.cpf }}</small>
      </b-card-text>
    </b-list-group-item>

    <b-list-group-item class="flex-column align-items-start">
      <div class="d-flex w-100 justify-content-between">
        <h5 class="mb-1">
          Dados Bancários
        </h5>
      </div>
      <b-card-text class="mb-1">
        <small class="d-block">{{ item.bank.bank }}</small>
        <small class="d-block">{{ item.bank.type }}</small>
        <small class="d-block">AG: {{ item.bank.agency }}</small>
        <small class="d-block">Conta: {{ item.bank.account }}</small>
      </b-card-text>
    </b-list-group-item>

    <b-list-group-item class="flex-column align-items-start">
      <div class="d-flex w-100 justify-content-between">
        <h5 class="mb-1">
          Recompensa valor
        </h5>
        <small class="text-muted">Vencimento: {{ convertDate(item.expireDate) }}</small>
      </div>
      <b-card-text class="mb-1">
        {{ item.rewardValue.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) }}
      </b-card-text>
    </b-list-group-item>
  </b-list-group>
</template>
