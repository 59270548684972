<script>
import { mapGetters } from 'vuex'
import {
  BCard, BCardBody, BRow, BCol, BBadge, BButton, VBModal, BModal, BTable, BPagination, BOverlay,
} from 'bootstrap-vue'

// import useJwt from '@/auth/jwt/useJwt'

import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import formatMobileNumber from '@/utils/formatMobilePhone'
import toConvertDateTimezoneToConventionalDate from '@/utils/toConvertDateTimezoneToConventionalDate'
import IndicateListFilters from './RewardsMoneyListFilters.vue'
import useRewardsMoneyList from './useRewardsMoneyList'
import rewardsStoreModule from '../rewardsStoreModule'
import RewardsMoneyModal from './RewardsMoneyModal.vue'

export default {
  components: {
    IndicateListFilters,

    BCard,
    BCardBody,
    BRow,
    BCol,
    // BFormInput,
    BTable,
    // BAvatar,
    BBadge,
    BPagination,
    BButton,
    BModal,
    vSelect,
    RewardsMoneyModal,
    BOverlay,
  },
  directives: {
    'b-modal': VBModal,
  },
  computed: {
    ...mapGetters({
      isAPICallInProgress: 'api/isAPICallInProgress',
    }),
  },
  methods: {
    handlePaymentClick(paymentToken) {
      this.handlePayment(paymentToken)
    },
    // handleValidate() {
    //   this.$swal({
    //     title: '<span class="d-block pt-1 pb-1 font-weight-bolder">Validar indicação</span>',
    //     html: `
    //     <p>
    //       Ao validar a indicação pela <u>primeira vez</u>, <b>será atualizado automaticamente o status</b> de CUPOM GERADO para CUPOM ATIVADO,  o indicador será notificado e <b>você terá até 30 dias para concluir a indicação</b>.
    //     </p>
    //     `,

    //     showCloseButton: true,
    //     showCancelButton: false,
    //     focusConfirm: false,
    //     confirmButtonText: 'Concordar e validar',
    //     confirmButtonAriaLabel: 'Thumbs up, great!',
    //     cancelButtonAriaLabel: 'Thumbs down',
    //     customClass: {
    //       confirmButton: 'btn btn-primary',
    //       cancelButton: 'btn btn-outline-danger ml-1',
    //     },
    //     buttonsStyling: false,
    //   })
    // },
  },
  setup() {
    const {
      fetchRewardsVoucher,
      tableColumns,
      perPage,
      currentPage,
      totalRewards,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRewardsMoneyListTable,
      refetchData,
      handlePayment,

      // UI
      resolveIndicateStageIcon,
      resolveIndicateStageVariant,

      // Extra Filters

      statusFilter,
      stageFilter,
      campaignsFilter,
      filterDatesFilter,

      //
      campaignsList,
    } = useRewardsMoneyList()
    const formatNumber = number => formatMobileNumber(number)
    const convertDate = date => toConvertDateTimezoneToConventionalDate(date)

    const INDICATE_APP_STORE_MODULE_NAME = 'app-rewards-money'
    if (!store.hasModule(INDICATE_APP_STORE_MODULE_NAME)) store.registerModule(INDICATE_APP_STORE_MODULE_NAME, rewardsStoreModule)
    onUnmounted(() => {
      if (store.hasModule(INDICATE_APP_STORE_MODULE_NAME)) store.unregisterModule(INDICATE_APP_STORE_MODULE_NAME)
    })

    const isFilterIndicateSidebarActive = ref(false)
    const currentRequest = ref({
      id: '#9635',
      payoutDate: null,
      requestDate: '15/01/2021',
      expireDate: '13/02/2021',
      rewardValue: 1500,
      indicator: {
        name: 'João da Silva Santos',
        phone: '21986142333',
        cpf: '145.960.627-22',
      },
      bank: {
        type: 'Conta corrente',
        bank: '030 - Banco do Brasil',
        agency: '0001',
        account: '1234-4',
      },
    })

    const statusOptions = [
      { label: 'Em aberto', value: 'Em aberto' },
      { label: 'Pago', value: 'Pago' },
    ]
    const stageOptions = [
      { label: 'Em andamento', value: 'Em andamento' },
      { label: 'Concluído', value: 'Concluido' },
      { label: 'Cancelado', value: 'Cancelado' },
    ]
    const filterDatesOptions = [
      { label: 'Data de cadastro', value: 'registrationDate' },
      { label: 'Vencimento do cupom', value: 'expireDate' },
      { label: 'Última atualização', value: 'lastUpdateDate' },
    ]
    // onBeforeMount(() => {
    //   fetchRewardsVoucher()
    // })
    const campaignsOptions = campaignsList

    const handleSelectRequestOpen = item => {
      currentRequest.value = item
    }

    return {

      // Sidebar
      isFilterIndicateSidebarActive,

      fetchRewardsVoucher,
      tableColumns,
      perPage,
      currentPage,
      totalRewards,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRewardsMoneyListTable,
      refetchData,
      handlePayment,

      // Filter
      avatarText,

      // UI
      resolveIndicateStageIcon,
      resolveIndicateStageVariant,
      formatNumber,
      convertDate,

      filterDatesOptions,
      campaignsOptions,
      statusOptions,
      stageOptions,

      // Extra Filters
      filterDatesFilter,
      campaignsFilter,
      statusFilter,
      stageFilter,

      // modal
      handleSelectRequestOpen,
      currentRequest,

    }
  },
}
</script>

<template>

  <div>
    <indicate-list-filters
      :is-filter-indicate-sidebar-active.sync="isFilterIndicateSidebarActive"

      :search-query.sync="searchQuery"
      :filter-dates-filter.sync="filterDatesFilter"
      :campaigns-filter.sync="campaignsFilter"
      :status-filter.sync="statusFilter"
      :stage-filter.sync="stageFilter"

      :filter-dates-options="filterDatesOptions"
      :campaigns-options="campaignsOptions"
      :status-options="statusOptions"
      :stage-options="stageOptions"

      @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostar</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>indicações</label>
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                class="btn-icon"
                @click="isFilterIndicateSidebarActive = true"
              >
                <feather-icon
                  icon="FilterIcon"
                />

              </b-button>
            </div>
          </b-col>
          <!-- Search -->
          <!-- <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block "
                placeholder="Pesquisar..."
              />

            </div>
          </b-col> -->
        </b-row>
        <!-- / Table Top -->

      </div>
      <b-overlay
        :show="isAPICallInProgress"
        rounded="sm"
      >
        <b-table
          ref="refRewardsMoneyListTable"
          class="position-relative"
          :items="fetchRewardsVoucher"
          responsive
          :fields="tableColumns"
          primary-key="coupon"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Não há registros :("
          :sort-desc.sync="isSortDirDesc"
        >
          <!-- <template #cell(stage)="{item}">
          <b-avatar
            :variant="`light-${resolveIndicateStageVariant(item.stage)}`"
          >

            <feather-icon
              :icon="resolveIndicateStageIcon(item.stage)"
              size="16"
              :class="`text-${resolveIndicateStageVariant(item.stage)}`"
            />
          </b-avatar>
        </template> -->
          <template #cell(indicator)="data">
            <div>
              <span class="font-weight-bold d-block text-nowrap">
                {{ data.item.indicator.name }}
              </span>
              <small class="text-muted d-block">{{ formatNumber(data.item.indicator.phone) }} <br> {{ data.item.indicator.cpf }}</small>
            </div>
          </template>
          <template #cell(payoutDate)="{item}">
            <div>
              <b-badge
                pill
                :variant="resolveIndicateStageVariant(item.stage)"
              >
                {{ item.status === 'Pendente' ? 'À pagar' : item.status }}
              </b-badge>
            </div>
          </template>
          <template #cell(expireDate)="{item}">
            {{ item.stage !== 'Cancelado' ? convertDate(item.expireDate) : 'Não se aplica' }}
          </template>
          <template #cell(actions)="{item}">
            <div v-if="$can('edit', 'rewardMoney')">
              <b-button
                v-if="item.status === 'Pendente'"
                v-b-modal.validate-reward
                variant="primary"
                class="btn-icon text-nowrap"
                @click="handleSelectRequestOpen(item)"
              >
                Baixar <feather-icon icon="LogInIcon" />
              </b-button>
              <span
                v-else
                class="font-weight-bold d-block text-nowrap"
              >
                {{ convertDate(item.payoutDate) }}
              </span>
            </div>
            <div v-else>
              <feather-icon icon="SlashIcon" />
            </div>
          </template>
        </b-table>
      </b-overlay>

      <!-- Footer -->
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ dataMeta.from }} até {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRewards"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
      <b-modal
        id="validate-reward"
        ok-only
        ok-title="Confirmar pagamento"
        centered
        size="lg"
        :title="`Solicitação ${currentRequest.id}`"
        @ok="handlePaymentClick(currentRequest.id)"
      >
        <div class="d-flex">
          <rewards-money-modal
            class="history"
            :item="currentRequest"
          />
          <div class="status text-center">
            <div class="">
              <small>Status atual</small>
              <br>
              <b-badge
                pill
                variant="light-danger"
                class="mt-1 "
              >
                <h5 class="text-danger px-1 m-0">
                  À pagar
                </h5>
              </b-badge>
            </div>
            <b-card class="mt-5">
              <b-card-body>Ao dar essa solicitação como paga você declara  que  remunerou seu parceiro!</b-card-body>
            </b-card>
          </div>
        </div>
      </b-modal>

    </b-card>

  </div>
</template>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.history {
  flex-grow: 1;

}
.status {
  margin-left: 2rem;
  max-width: calc(50% - 1rem);
}
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .swal2-popup {
    background-image: url('/images/alert-bg.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 0 0;
  }
</style>
